import React from "react";
import Star from "./star";
import managerImage from "../images/manager.svg";
import leftImage from "../images/icon/left.svg";
import rightImage from "../images/icon/right.svg";
export default function Comments() {
  return (
    <section className="bg-white  pt-12 sm:pt-32">
      <div className="container mx-auto">
        <div className="text-center">
          <h2 className="text-2xl sm:text-3xl text-gray-900 font-light">
            从您开始，让进步发生
          </h2>

          <div className="mt-16">
            <div className="flex flex-col sm:flex-row justify-around items-center text-left">
              <div className="w-8 h-8 rounded-full shadow-md hidden sm:flex items-center justify-center block">
                <img alt="left" src={leftImage} />
              </div>
              <div className=" rounded  shadow-lg px-4 py-5 max-w-xs text-gray-600 text-sm font-normal mb-6 sm:mb-0">
                <Star />
                <div className="mt-4 mb-2">中快餐饮集团苏州分公司</div>
                <div className="font-light my-2">
                  “我们承包了几十家学校食堂，每天档口通过微信群下单，采购部手工录入电脑，通过表格进行汇总分发给供应商，再加上收货环节，光是整理订单就需要5个人，每天从早干到晚。
                  使用斯马千系统以后只要一个人就能轻松完成所有工作，就算以后有再多的项目，再也不用担心人手问题了。”
                </div>
                <div className="flex items-center my-4">
                  <img alt="manager" src={managerImage} />
                  <div className="ml-2">采购部：吴经理</div>
                </div>
              </div>
              <div className="b rounded  shadow-lg px-4 py-5  max-w-xs text-gray-600 text-sm font-normal  mb-6 sm:mb-0">
                <Star />
                <div className="mt-4 mb-2">书香世家酒店管理集团</div>
                <div className="font-light my-2">
                  “以前最怕的就是食品安全问题，一旦出现集体食品卫生问题，很难找到问题根源，损失难以估量。
                  自从使用了斯马千系统以后，不但对供应商的监管和食品溯源的老大难问题迎刃而解，而且标准化的系统管理对于新项目的拓展也是强有力的销售武器！”
                </div>
                <div className="flex items-center my-6">
                  <img alt="manager" src={managerImage} />
                  <div className="ml-2">总经理：朱总</div>
                </div>
              </div>
              <div className="b rounded  shadow-lg px-4 py-5 max-w-xs text-gray-600 text-sm font-normal mb-6 sm:mb-0">
                <Star />
                <div className="mt-4 mb-2">
                  苏州南环桥农贸市场农副产品批发商
                </div>
                <div className="font-light my-2">
                  “
                  去年有一个月报表显示某客户采购额突然比之前高出很多，通过之前的财务统计根本找不出问题所在。
                  现在所有的数据都清晰可查，随时随地打开手机经营状况一目了然，报表功能还可以帮我分析客户的各种需求，斯马千系统真是我的经营好帮手！”
                </div>
                <div className="flex  items-center my-6">
                  <img alt="manager" src={managerImage} />
                  <div className="ml-2">老板：顾总</div>
                </div>
              </div>
              <div className="w-8 h-8 rounded-full shadow-md hidden sm:flex items-center justify-center ">
                <img alt="right" src={rightImage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
