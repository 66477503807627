import React from "react";
import feature1Image from "../images/feature/1.svg";
import feature2Image from "../images/feature/2.svg";
import feature3Image from "../images/feature/3.svg";
export default function Feature() {
  return (
    <section className=" py-16 sm:py-32 bg-gray-97 font-light">
      <div className="container mx-auto">
        <div className="text-center">
          <h2 className="text-2xl sm:text-3xl text-gray-900 font-light">
            我们如何做到？
          </h2>
          <div className="text-gray-600    px-4 mt-4 sm:px-0 font-light">
            通过三大核心功能，您的业务就变成了智能业务，每天都比竞争对手越跑越快
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-center mt-12 sm:mt-20 mx-auto text-center px-0 sm:px-20">
          <div className="w-full sm:w-auto ">
            <div className="flex flex-col items-center  mb-8 sm:mb-0  p-8 sm:p-2 mx-8 sm:mx-0 border-gray-300 border-b sm:border-0">
              <img
                alt="p1"
                className=""
                src={feature1Image}
                style={{ height: 100 }}
              />
              <h3 className="mt-6 text-gray-1111 text-lg font-light">
                网络协同
              </h3>
              <div
                className="text-gray-600 text-sm mt-1 leading-relaxed"
                style={{ maxWidth: 264 }}
              >
                所有参与者通过信息化手段，用最少的人力资源，协同作业完成工作
              </div>
            </div>
          </div>
          <div className="w-full sm:w-auto ">
            <div className="flex flex-col items-center  mb-8 sm:mb-0  p-8 mx-8 border-gray-300 border-b sm:border-0">
              <img
                alt="p1"
                className=""
                src={feature2Image}
                style={{ height: 100 }}
              />
              <h3 className="mt-6 text-gray-1111 text-lg font-light">
                食安追溯
              </h3>
              <div
                className="text-gray-600 text-sm mt-1 leading-relaxed"
                style={{ maxWidth: 264 }}
              >
                食品安全监管专业化、规范化可提高安全保障，降低运营风险
              </div>
            </div>
          </div>
          <div className="w-full sm:w-auto ">
            <div className="flex flex-col items-center w-full sm:w-auto pb-4">
              <img
                alt="p1"
                className=""
                src={feature3Image}
                style={{ height: 100 }}
              />
              <h3 className="mt-6 text-gray-1111 text-lg font-light">
                数据运营
              </h3>
              <div
                className="text-gray-600 text-sm mt-1 leading-relaxed"
                style={{ maxWidth: 264 }}
              >
                大数据辅助决策，数据供应链技术可实现企业日常食材供给的精细化管理
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
