import React from "react";

import SEO from "../components/seo";

import Header from "../components/header";
import Hero from "../components/hero";
import Feature from "../components/feature";
import Layout from "../components/layout";
import Steps from "../components/steps";
import Modules from "../components/modules";
import Pattern from "../components/pattern";
import Price from "../components/price";
import Partners from "../components/partners";
import Comments from "../components/comments";
function IndexPage() {
  return (
    <Layout>
      <SEO keywords={[`斯马千供应链系统`]} title="斯马千" />
      <Hero />
      <a id="solution" name="solution"></a>
      <Pattern />
      <Feature />
      <Steps />
      <Modules />
      <a id="price" name="price"></a>
      <Price />
      <a id="client" name="client"></a>
      <Comments />
      <Partners />
    </Layout>
  );
}

export default IndexPage;
