import React from "react";
import module1Image from "../images/modules/1.svg";
import module2Image from "../images/modules/2.svg";
import module3Image from "../images/modules/3.svg";

import module4Image from "../images/modules/4.svg";
import module5Image from "../images/modules/5.svg";
import module6Image from "../images/modules/6.svg";
export default function Modules() {
  return (
    <section className="bg-white  sm:py-20">
      <div className="container mx-auto">
        <div className="flex flex-col flex-wrap md:flex-row sm:px-8">
          <div className=" sm:w-1/3 mb-12 sm:mb-24">
            <div className="mb-8">
              <img
                alt=""
                className="  mx-auto sm:ml-0 h-12 w-12"
                src={module1Image}
              />
            </div>
            <div className="text-center   sm:text-left sm:w-1/2 sm:ml-0 mt-6 sm:mt-0">
              <h3 className="text-gray-1111 leading-relaxed text-xl font-normal mb-1">
                导入/导出
              </h3>
              <div
                className="text-gray-600 text-sm px-4 sm:px-0 font-normal"
                style={{ minWidth: 260 }}
              >
                将Excel模版中或从供应商导入商品至商品库管理，同时可以按需导出
              </div>
            </div>
          </div>

          <div className=" sm:w-1/3  mb-12 sm:mb-24">
            <div className="mb-8">
              <img
                alt=""
                className="  mx-auto sm:ml-0 h-12 w-12"
                src={module2Image}
              />
            </div>
            <div className="text-center   sm:text-left sm:w-1/2 sm:ml-0 mt-6 sm:mt-0">
              <h3 className="text-gray-1111 leading-relaxed text-xl font-normal mb-1">
                商城
              </h3>
              <div
                className="text-gray-600 text-sm px-4 sm:px-0 font-normal"
                style={{ minWidth: 260 }}
              >
                供应商供应商品信息即时掌握，每日采购省时便捷
              </div>
            </div>
          </div>
          <div className=" sm:w-1/3  mb-12 sm:mb-24">
            <div className="mb-8">
              <img
                alt=""
                className="  mx-auto sm:ml-0 h-12 w-12"
                src={module3Image}
              />
            </div>
            <div className="text-center   sm:text-left sm:w-1/2 sm:ml-0 mt-6 sm:mt-0">
              <h3 className="text-gray-1111 leading-relaxed text-xl font-normal mb-1">
                流转单据
              </h3>
              <div
                className="text-gray-600 text-sm px-4 sm:px-0 font-normal"
                style={{ minWidth: 260 }}
              >
                日常订单流转单据无纸化管理，同时支持打印
              </div>
            </div>
          </div>

          <div className=" sm:w-1/3  mb-12 sm:mb-24">
            <div className="mb-8">
              <img
                alt=""
                className="  mx-auto sm:ml-0 h-12 w-12"
                src={module4Image}
              />
            </div>
            <div className="text-center   sm:text-left sm:w-1/2 sm:ml-0 mt-6 sm:mt-0">
              <h3 className="text-gray-1111 leading-relaxed text-xl font-normal mb-1">
                电子台账
              </h3>
              <div
                className="text-gray-600 text-sm px-4 sm:px-0 font-normal"
                style={{ minWidth: 260 }}
              >
                自动生成每日采购电子台账，方便政府监管部门查询
              </div>
            </div>
          </div>

          <div className=" sm:w-1/3  mb-12 sm:mb-24">
            <div className="mb-8">
              <img
                alt=""
                className="  mx-auto sm:ml-0 h-12 w-12"
                src={module5Image}
              />
            </div>
            <div className="text-center   sm:text-left sm:w-1/2 sm:ml-0 mt-6 sm:mt-0">
              <h3 className="text-gray-1111 leading-relaxed text-xl font-normal mb-1">
                权限管理
              </h3>
              <div
                className="text-gray-600 text-sm px-4 sm:px-0 font-normal"
                style={{ minWidth: 260 }}
              >
                对于员工，供应商和买家进行精细管理，保证安全准确有序
              </div>
            </div>
          </div>
          <div className=" sm:w-1/3  mb-12 sm:mb-24">
            <div className="mb-8">
              <img
                alt=""
                className="  mx-auto sm:ml-0 h-12 w-12"
                src={module6Image}
              />
            </div>
            <div className="text-center   sm:text-left sm:w-1/2 sm:ml-0 mt-6 sm:mt-0">
              <h3 className="text-gray-1111 leading-relaxed text-xl font-normal mb-1">
                数据报表
              </h3>
              <div
                className="text-gray-600 text-sm px-4 sm:px-0 font-normal"
                style={{ minWidth: 260 }}
              >
                每日经营状况随时可见，支持高级查询上下游交易往来
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
