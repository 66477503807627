import React from "react";

export default function Star() {
  return (
    <div className="start flex">
      {[1, 2, 3, 4, 5].map(item => (
        <svg
          className="h-5 w-5"
          fill="none"
          key={`kkk${item}`}
          viewBox="0 0 20 16"
        >
          <path d="M.146 0H19.29v16H.146z" fill="#000" opacity=".01" />
          <path
            d="M17.405 6.712h-5.892l-1.825-4.75-1.825 4.75H1.971l4.786 2.9-1.825 4.725 4.786-2.925 4.786 2.925-1.885-4.725 4.786-2.9z"
            fill="#F7B500"
          />
          <path
            clipRule="evenodd"
            d="M11.991 6.187h7.299l-5.923 3.625 2.273 5.85-5.922-3.625-5.953 3.6 2.274-5.85-5.893-3.6h7.299L9.718.337l2.273 5.85zM6.757 9.612l-1.825 4.725 4.786-2.925 4.786 2.925-1.885-4.725 4.786-2.9h-5.892l-1.825-4.75-1.825 4.75H1.971l4.786 2.9z"
            fill="#F7B500"
            fillRule="evenodd"
          />
        </svg>
      ))}
    </div>
  );
}
