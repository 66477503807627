import React from "react";

import PriceImage from "../images/price.svg";
export default function Price() {
  return (
    <section className="bg-gray-97 py-16 sm:py-32 font-light">
      <div className="flex flex-col sm:flex-row justify-around items-center mx-0 sm:mx-16">
        <div className="p-4 sm:p-0 sm:w-1/2">
          <img
            alt="price"
            className="mx-auto w-10/12 sm:w-/11/12"
            src={PriceImage}
            style={{ maxWidth: 577 }}
          />
        </div>
        <div className="mt-6 sm:mt-0  px-6 sm:w-1/2">
          <h2 className="text-xl sm:text-3xl  text-gray-40 font-normal sm:font-light">
            单价
          </h2>
          <p
            className="text-gray-600 text-base tm:text-lg font-light my-4 leading-relaxed "
            style={{ maxWidth: 577 }}
          >
            用户注册后可以邀请所有上下游企业参与协作，系统根据邀请
            <span className="font-normal" style={{ color: `#536DFE` }}>
              *买家用户
            </span>
            的数量按月收费，邀请供应商无需收费，被邀请的买家和供应商用户均免费使用相应功能。
          </p>
          {/* <h2 className="text-xl sm:text-3xl text-gray-40 font-normal sm:font-light">
            ¥99/买家用户/月
          </h2> */}
          <div
            className="font-normal tesxt-sm mt-2"
            style={{ color: `#536DFE` }}
          >
            *买家用户：包含门店，档口和客户
          </div>
        </div>
      </div>
    </section>
  );
}
