import React from "react";

import p1Image from "../images/partners/1.jpg";
import p2Image from "../images/partners/2.jpg";
import p3Image from "../images/partners/3.jpg";
export default function Partners() {
  return (
    <section className="bg-white py-10 sm:py-20 font-light">
      <div className="container mx-auto">
        <div className="  text-center flex flex-col sm:flex-row justify-around items-center">
          <div className="">
            <img alt="p1" src={p1Image} />
          </div>

          <div className="my-8 sm:my-0">
            <img alt="p2" src={p2Image} />
          </div>

          <div className=" ">
            <img alt="p3" src={p3Image} />
          </div>
        </div>
      </div>
    </section>
  );
}
