import React from "react";
import step1Image from "../images/steps/1.svg";
import step2Image from "../images/steps/2.svg";
import step3Image from "../images/steps/3.svg";

import step4Image from "../images/steps/4.svg";
import step5Image from "../images/steps/5.svg";
import step6Image from "../images/steps/6.svg";

export default function Steps() {
  return (
    <section className="bg-white py-10 sm:py-32 font-light">
      <div className="container mx-auto">
        <div className="mx-auto flex flex-col justify-around items-center sm:flex-row sm:w-10/12">
          <div className=" sm:w-1/2">
            <img
              alt=""
              className="py-2 mx-auto w-10/12 sm:w-auto "
              src={step1Image}
            />
          </div>
          <div className="mx-auto sm:w-1/2 sm:ml-20 mt-6 sm:mt-0">
            <h3 className="text-gray-1111 leading-relaxed text-xl sm:text-3xl ">
              轻松搭建销售平台
            </h3>
            <ul className="pl-4 text-gray-600 mt-3 list-ss">
              <li className="py-1">多种方式上传商品</li>
              <li className="py-1">不同买家专享价格</li>
              <li className="py-1">报价单一键生成</li>
            </ul>
          </div>
        </div>

        <div className="my-32 sm:my-48 mx-auto flex flex-col justify-around items-center sm:flex-row sm:w-10/12">
          <div className=" sm:w-1/2 order-0 sm:order-2">
            <img
              alt=""
              className="py-2 mx-auto  w-10/12 sm:w-auto "
              src={step2Image}
            />
          </div>
          <div className="mx-auto sm:w-1/2 sm:ml-20 mt-6 sm:mt-0 order-0 sm:order-1">
            <h3 className="text-gray-1111 leading-relaxed text-xl sm:text-3xl font-light">
              像网购一样采购食材
            </h3>
            <ul className="pl-4 text-gray-600 mt-3 list-ss">
              <li className="py-1">告别传统叫货模式</li>
              <li className="py-1">每天5分钟轻松完成采购</li>
              <li className="py-1">供应商供货信息变化随时可见</li>
            </ul>
          </div>
        </div>

        <div className="mx-auto flex flex-col justify-around items-center sm:flex-row sm:w-10/12">
          <div className=" sm:w-1/2">
            <img
              alt=""
              className="py-2 mx-auto  w-10/12 sm:w-auto "
              src={step3Image}
            />
          </div>
          <div className="mx-auto sm:w-1/2 sm:ml-20 mt-6 sm:mt-0">
            <h3 className="text-gray-1111 leading-relaxed text-xl sm:text-3xl font-light">
              订单管理告别人工
            </h3>
            <ul className="pl-4 text-gray-600 mt-3 list-ss">
              <li className="py-1">订单流转全程自动</li>
              <li className="py-1">各种随货单据自动生成</li>
              <li className="py-1">订单信息透明，减小内外部沟通成本</li>
            </ul>
          </div>
        </div>

        <div className="my-32 sm:my-48 mx-auto flex flex-col justify-around items-center sm:flex-row sm:w-10/12">
          <div className=" sm:w-1/2 order-0 sm:order-2">
            <img
              alt=""
              className="py-2 mx-auto w-10/12 sm:w-auto "
              src={step4Image}
            />
          </div>
          <div className="mx-auto sm:w-1/2 sm:ml-20 mt-6 sm:mt-0 order-0 sm:order-1">
            <h3 className="text-gray-1111 leading-relaxed text-xl sm:text-3xl font-light">
              食品安全操作规范化
            </h3>
            <ul className="pl-4 text-gray-600 mt-3 list-ss">
              <li className="py-1">实时抓取票证对应批次管理</li>
              <li className="py-1">电子台账自动生成</li>
              <li className="py-1">配合监管部门实时查询</li>
              <li className="py-1">食品安全问题实时预警</li>
            </ul>
          </div>
        </div>

        <div className="mx-auto flex flex-col justify-around items-center sm:flex-row sm:w-10/12">
          <div className=" sm:w-1/2">
            <img
              alt=""
              className="py-2 mx-auto w-10/12 sm:w-auto "
              src={step5Image}
            />
          </div>
          <div className="mx-auto sm:w-1/2 sm:ml-20 mt-6 sm:mt-0">
            <h3 className="text-gray-1111 leading-relaxed text-xl sm:text-3xl font-light">
              重塑管理模式
            </h3>
            <ul className="pl-4 text-gray-600 mt-3 list-ss">
              <li className="py-1">工作流程透明化</li>
              <li className="py-1">上下游协作紧密化</li>
              <li className="py-1">内部管理扁平化</li>
            </ul>
          </div>
        </div>

        <div className="mt-20 mb-8 sm:mt-48 mx-auto flex flex-col justify-around items-center sm:flex-row sm:w-10/12">
          <div className=" sm:w-1/2 order-0 sm:order-2">
            <img
              alt=""
              className="py-2 mx-auto w-10/12 sm:w-auto "
              src={step6Image}
            />
          </div>
          <div className="mx-auto sm:w-1/2 sm:ml-20 mt-6 sm:mt-0 order-0 sm:order-1">
            <h3 className="text-gray-1111 leading-relaxed text-xl sm:text-3xl font-light">
              数据告诉经营真相
            </h3>
            <ul className="pl-4 text-gray-600 mt-3 list-ss">
              <li className="py-1">账目清晰</li>
              <li className="py-1">活数据辅助经营</li>
              <li className="py-1">提供真实可靠的征信数据</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
