import React from "react";

export default function Hero() {
  return (
    <div className="hero">
      <div className="hero__overlay absolute h-full w-full"></div>
      <div className="h-screen flex flex-col  sm:justify-center items-center relative z-30">
        <h1 className="text-xl w-full mt-40 mx-4 text-center sm:mx-0 sm:mt-0 sm:text-4xl">
          一个专门为团餐企业和供应商协作的工具
        </h1>
        <p
          className="text-base max-w-lg leading-loose
           sm:leading-loose mt-6 
        text-center mx-4 sm:mx-0 font-light"
        >
          通过斯马千供应链系统，企业可搭建专属平台，链接上下游共同协作优化运营环节，帮助企业在实现信息化，标准化的基础上提升竞争力
        </p>
        <div className="mt-6  sm:mt-40">
          <button className="block  p-button">开始使用</button>
        </div>
      </div>
    </div>
  );
}
