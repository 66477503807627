import React from "react";
import pattern1Image from "../images/pattern/1.svg";
import pattern2Image from "../images/pattern/2.svg";
import pattern3Image from "../images/pattern/3.svg";

export default function Pattern() {
  return (
    <section className="bg-gray-97 py-16 sm:py-32 font-light">
      <a href=""></a>
      <div className="container mx-auto">
        <div className="text-center">
          <h2 className="text-2xl sm:text-3xl text-gray-900 ">
            团餐2.0时代的运营模式
          </h2>
          <div className="text-gray-600    px-4 mt-4 sm:px-0 font-light">
            平台型企业可以更好地服务客户,推动产业链变革
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-center mt-12 sm:mt-20 mx-auto text-center px-0 sm:px-48">
          <div className="flex flex-col items-center  mb-8 sm:mb-0">
            <img alt="p1" className="" src={pattern1Image} />
            <h3 className="mt-6 text-gray-1111 text-lg font-light">规模化</h3>
            <div className="text-gray-600 text-sm mt-1">
              供应链环节集约化，降低成本
            </div>
          </div>
          <div className="flex flex-col items-center mb-8 sm:mb-0">
            <img alt="p1" className="" src={pattern2Image} />
            <h3 className="mt-6 text-gray-1111 text-lg font-light">轻资产</h3>
            <div className="text-gray-600 text-sm mt-1">
              专注于运营，提升行业效率
            </div>
          </div>
          <div className="flex flex-col items-center">
            <img alt="p1" className="" src={pattern3Image} />
            <h3 className="mt-6 text-gray-1111 text-lg font-light">
              快复制及扩张
            </h3>
            <div className="text-gray-600 text-sm mt-1">
              洞察市场动态，实现迅速扩张
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
